import React from "react";
import _ from "lodash";
import Expertise from '../components/Expertise'
import Icon from '../components/Icon';
import digital from '../media/icons/new/1-digital.svg';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'conseil-innovation',
      title: "Akimed - Conseil en innovation dans la santé",
      description: "Akimed vous accompagne sur le développement et l'accélération de votre stratégie digitale et data dans les métiers de la santé.",
      content:
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                <p>Toute révolution se prépare et être prêt à travailler différemment demande beaucoup d’engagement. La révolution qui a démarré en santé va avoir un impact important sur la façon de traiter les patients et aborder les problèmes de santé publiques de demain.</p>
                <p>Akimed travaille sur les sujets suivants pour préparer les équipes à <strong>mieux aborder les problématiques digitales</strong> connues dans la santé&nbsp;:</p>
                <ul>
                    <li>Evaluation de maturité</li>
                    <li>Gestion du changement</li>
                    <li>Analyse d’écart et risque</li>
                    <li>Plan d’action & roadmap</li>
                    <li>Design thinking</li>
                    <li>Analyse & cartographie des processus</li>
                    <li>Business Plan</li>
                </ul>
                </div>
            </div>
            <div class="column alt-column is-2 is-offset-1">
                <div class="content is-medium">
                <figure class="image is-50x50">
                    <Icon src={digital} />
                </figure>
                </div>
            </div>
        </div>
    };
  }

  render() {
    return (
        <Expertise 
          content={this.state.content}
          activePage={this.state.page} 
          title={this.state.title} 
          description={this.state.description}
        />
    );
  }
}
